import _objectSpread from "/builds/t1_X_M4-j/0/opiniooon/opnn-analytics/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import LocationGroupSelect from '@/components/shared/LocationGroupSelect';
import { SurveyAnalysis } from '@/services/ResourceService';
import QueryService from '@/services/QueryService';
import QueryUtils from '@/services/utils/QueryUtils';
import ClearFilter from '@/components/shared/ClearFilter';
import EmptyState from '@/components/shared/EmptyState';
import Survey from '@/components/survey-analysis/Survey';
import SurveySelect from '@/components/survey-analysis/SurveySelect';
import opnDateRangePicker from '@/components/shared/opnDateRangePicker';
import CompanyPropertiesService from '@/services/CompanyPropertiesService';
import ColorLegend from '@/components/shared/ColorLegend';
import SearchFilterSelector from '@/components/shared/saved-search-filter/SearchFilterSelector';
import { mapState } from 'vuex';
export default {
  name: 'SurveyAnalysis',
  components: {
    SearchFilterSelector: SearchFilterSelector,
    Survey: Survey,
    SurveySelect: SurveySelect,
    LocationGroupSelect: LocationGroupSelect,
    opnDateRangePicker: opnDateRangePicker,
    ClearFilter: ClearFilter,
    EmptyState: EmptyState,
    ColorLegend: ColorLegend
  },
  data: function data() {
    return {
      surveyListIsLoading: false,
      surveyIsLoading: false,
      surveyList: [],
      selectedSurveyId: null,
      survey: {},
      noData: false,
      filter: {
        location: {},
        dateRange: []
      },
      surveyAnalysisFilter: QueryUtils.getSurveyAnalysisFilter()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState({
    currentUser: function currentUser(state) {
      return state.general.user;
    }
  })), {}, {
    currentUserIsSuperAdmin: function currentUserIsSuperAdmin() {
      return this.currentUser.authorities.includes('ROLE_SUPER_ADMIN');
    },
    queryParameters: function queryParameters() {
      return {
        companyId: this.$store.state.general.companyId,
        locationIds: this.filter.location.queryLocations,
        start: moment(this.filter.dateRange[0]).format('DD.MM.YYYY'),
        end: moment(this.filter.dateRange[1]).format('DD.MM.YYYY')
      };
    },
    isLoading: function isLoading() {
      return this.surveyListIsLoading || this.surveyIsLoading;
    }
  }),
  watch: {
    $route: function $route() {
      this.initializePage();
    }
  },
  mounted: function mounted() {
    this.initializePage();
  },
  methods: {
    initializePage: function initializePage() {
      this.surveyListIsLoading = true;
      var filter = QueryService.get('filter') || {};
      var surveyAnalysisFilter = QueryService.get('surveyAnalysisFilter') || {};
      this.filter.dateRange = QueryUtils.getDateRangeFilter(filter.dateRange);
      this.filter.location = QueryUtils.getLocationFilter(filter.location);
      this.surveyAnalysisFilter = QueryUtils.getSurveyAnalysisFilter(surveyAnalysisFilter);
      this.loadSurveyListAndThenSurvey();
    },
    loadSurveyListAndThenSurvey: function loadSurveyListAndThenSurvey() {
      var _this = this;
      this.surveyListIsLoading = true;
      this.surveyList = [];
      this.selectedSurveyId = null;
      this.noData = true;
      Promise.all([SurveyAnalysis.getSurveyOptions(this.queryParameters.companyId, this.queryParameters.locationIds, this.queryParameters.start, this.queryParameters.end), CompanyPropertiesService.update()]).then(function (responses) {
        var data = responses[0].data;
        _this.surveyListIsLoading = false;
        _this.surveyList = data;
        if (_this.surveyList.length > 0) {
          _this.noData = false;
          if (_this.surveyAnalysisFilter.surveyId === null) {
            _this.selectedSurveyId = _this.surveyList[0].surveyId;
          } else if (_this.surveyList.find(function (item) {
            return item.surveyId === _this.surveyAnalysisFilter.surveyId;
          })) {
            _this.selectedSurveyId = _this.surveyAnalysisFilter.surveyId;
          } else {
            _this.selectedSurveyId = _this.surveyList[0].surveyId;
            _this.changeSurveyIdInRouteParams();
          }
          _this.loadSurvey();
        }
      }, function () {
        _this.surveyListIsLoading = false;
      });
    },
    loadSurvey: function loadSurvey() {
      var _this2 = this;
      this.surveyIsLoading = true;
      this.survey = {};
      SurveyAnalysis.getData(this.$store.state.general.companyId, this.filter.location.queryLocations, this.selectedSurveyId, moment(this.filter.dateRange[0]).format('DD.MM.YYYY'), moment(this.filter.dateRange[1]).format('DD.MM.YYYY')).then(function (response) {
        _this2.survey = response.data;
        _this2.surveyIsLoading = false;
      }, function () {
        _this2.surveyIsLoading = false;
      });
    },
    dateRangeChanged: function dateRangeChanged(dateRange) {
      this.filter.dateRange = dateRange;
      QueryService.put('filter', this.filter);
    },
    locationsChanged: function locationsChanged(locationFilter) {
      this.filter.location = locationFilter;
      QueryService.put('filter', this.filter);
    },
    changeSurveyId: function changeSurveyId(surveyId) {
      this.selectedSurveyId = surveyId;
      this.changeSurveyIdInRouteParams();
      this.loadSurvey();
    },
    changeSurveyIdInRouteParams: function changeSurveyIdInRouteParams() {
      this.surveyAnalysisFilter.surveyId = this.selectedSurveyId;
      QueryService.put('surveyAnalysisFilter', this.surveyAnalysisFilter);
    }
  }
};