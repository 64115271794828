import { render, staticRenderFns } from "./Comparison.vue?vue&type=template&id=2b89052d&scoped=true&"
import script from "./Comparison.vue?vue&type=script&lang=js&"
export * from "./Comparison.vue?vue&type=script&lang=js&"
import style0 from "./Comparison.vue?vue&type=style&index=0&id=2b89052d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b89052d",
  null
  
)

/* custom blocks */
import block0 from "./Comparison.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/builds/t1_X_M4-j/0/opiniooon/opnn-analytics/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2b89052d')) {
      api.createRecord('2b89052d', component.options)
    } else {
      api.reload('2b89052d', component.options)
    }
    module.hot.accept("./Comparison.vue?vue&type=template&id=2b89052d&scoped=true&", function () {
      api.rerender('2b89052d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/Comparison.vue"
export default component.exports