import { render, staticRenderFns } from "./SelectionBox.vue?vue&type=template&id=4f0638a5&scoped=true&"
import script from "./SelectionBox.vue?vue&type=script&lang=js&"
export * from "./SelectionBox.vue?vue&type=script&lang=js&"
import style0 from "./SelectionBox.vue?vue&type=style&index=0&id=4f0638a5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f0638a5",
  null
  
)

/* custom blocks */
import block0 from "./SelectionBox.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/builds/t1_X_M4-j/0/opiniooon/opnn-analytics/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4f0638a5')) {
      api.createRecord('4f0638a5', component.options)
    } else {
      api.reload('4f0638a5', component.options)
    }
    module.hot.accept("./SelectionBox.vue?vue&type=template&id=4f0638a5&scoped=true&", function () {
      api.rerender('4f0638a5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/comparison/SelectionBox.vue"
export default component.exports