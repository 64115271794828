import { render, staticRenderFns } from "./GoalTable.vue?vue&type=template&id=45e49a22&scoped=true&"
import script from "./GoalTable.vue?vue&type=script&lang=js&"
export * from "./GoalTable.vue?vue&type=script&lang=js&"
import style0 from "./GoalTable.vue?vue&type=style&index=0&id=45e49a22&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45e49a22",
  null
  
)

/* custom blocks */
import block0 from "./GoalTable.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/builds/t1_X_M4-j/0/opiniooon/opnn-analytics/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('45e49a22')) {
      api.createRecord('45e49a22', component.options)
    } else {
      api.reload('45e49a22', component.options)
    }
    module.hot.accept("./GoalTable.vue?vue&type=template&id=45e49a22&scoped=true&", function () {
      api.rerender('45e49a22', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/goal/GoalTable.vue"
export default component.exports