import { render, staticRenderFns } from "./ChangeOverTime.vue?vue&type=template&id=867367c6&scoped=true&"
import script from "./ChangeOverTime.vue?vue&type=script&lang=js&"
export * from "./ChangeOverTime.vue?vue&type=script&lang=js&"
import style0 from "./ChangeOverTime.vue?vue&type=style&index=0&id=867367c6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "867367c6",
  null
  
)

/* custom blocks */
import block0 from "./ChangeOverTime.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/builds/t1_X_M4-j/0/opiniooon/opnn-analytics/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('867367c6')) {
      api.createRecord('867367c6', component.options)
    } else {
      api.reload('867367c6', component.options)
    }
    module.hot.accept("./ChangeOverTime.vue?vue&type=template&id=867367c6&scoped=true&", function () {
      api.rerender('867367c6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/shared/change-over-time/ChangeOverTime.vue"
export default component.exports