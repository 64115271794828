import "/builds/t1_X_M4-j/0/opiniooon/opnn-analytics/node_modules/@vue/babel-preset-app/node_modules/core-js/modules/es.array.from.js";
import "/builds/t1_X_M4-j/0/opiniooon/opnn-analytics/node_modules/@vue/babel-preset-app/node_modules/core-js/modules/es.number.constructor.js";
import "/builds/t1_X_M4-j/0/opiniooon/opnn-analytics/node_modules/@vue/babel-preset-app/node_modules/core-js/modules/es.object.assign.js";
import "/builds/t1_X_M4-j/0/opiniooon/opnn-analytics/node_modules/@vue/babel-preset-app/node_modules/core-js/modules/es.promise.js";
import "/builds/t1_X_M4-j/0/opiniooon/opnn-analytics/node_modules/@vue/babel-preset-app/node_modules/core-js/modules/es.symbol.js";
import Vue from 'vue';
import Vuedals from 'vuedals';

// set default language to English, because of the first day of the week, which is Sunday in the US
import 'moment/locale/en-gb';
import App from '@/App.vue';
import Bootstrap from '@/config/Bootstrap';
import Router from '@/config/Router';
import Store from '@/store/Store';
import Toaster from '@/config/Toaster';
import InternetExplorer from '@/config/InternetExplorer';
import Translation from '@/config/Translation';
import '@/config/FontAwesome';
import '@/directives/HasAuthority';
import '@/filters/LanguageAwareString';
import Number from '@/filters/Number';
import 'typeface-roboto';
import '@/styles/global.scss';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import KeepAlive from '@/config/KeepAlive';
Vue.config.productionTip = false;
Vue.use(Vuedals);
Vue.use(Number);
Toaster.initToaster();
Bootstrap.bootstrap();
InternetExplorer.initialize();
KeepAlive.init();
new Vue({
  router: Router,
  store: Store,
  i18n: Translation,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');