import _objectSpread from "/builds/t1_X_M4-j/0/opiniooon/opnn-analytics/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Panel from '@/components/shared/Panel';
import Alert from '@/components/shared/Alert';
import LocationGroupSelect from '@/components/shared/LocationGroupSelect';
import opnDateRangePicker from '@/components/shared/opnDateRangePicker';
import { API_URL } from '@/config/constants';
import { CsvCheck } from '@/services/ResourceService';
import QueryUtils from '@/services/utils/QueryUtils';
import moment from 'moment';
import FormatUtils from '@/services/utils/FormatUtils';
import CompanyPropertiesService from '@/services/CompanyPropertiesService';
import SearchFilterSelector from '@/components/shared/saved-search-filter/SearchFilterSelector';
import { mapState } from 'vuex';
export default {
  name: 'CsvExport',
  components: {
    Panel: Panel,
    Alert: Alert,
    LocationGroupSelect: LocationGroupSelect,
    opnDateRangePicker: opnDateRangePicker,
    SearchFilterSelector: SearchFilterSelector
  },
  data: function data() {
    var _this = this;
    var allowedLanguages = ['DE', 'EN', 'FR', 'IT'];
    return {
      isLoading: false,
      noData: false,
      checkData: {},
      form: {
        type: 'feedbacks',
        matrixExportAggregateByLocationGroup: false,
        locationIds: [],
        dateRange: []
      },
      filter: {
        location: {}
      },
      language: function () {
        return _this.$store.state && _this.$store.state.general.user && _this.$store.state.general.user.language && allowedLanguages.includes(_this.$store.state.general.user.language) ? _this.$store.state.general.user.language : 'DE';
      }(),
      allowedLanguages: allowedLanguages,
      charset: 'utf8',
      dateFormat: 'DD.MM.YYYY',
      checkIsWaiting: false,
      enableFormWatcher: false
    };
  },
  mounted: function mounted() {
    var inputDateFormat = 'YYYY-MM-DD';
    var allowedTypes = ['feedbacks', 'reasons', 'matrix'];
    var queryParams = this.$route.query;
    if (queryParams.type && allowedTypes.includes(queryParams.type)) {
      this.form.type = queryParams.type;
    }
    if (queryParams.locationFilter) {
      this.changeLocations(queryParams.locationFilter);
    }
    if (queryParams.start && queryParams.end && moment(queryParams.start, inputDateFormat, true).isValid() && moment(queryParams.end, inputDateFormat, true).isValid()) {
      this.dateRangeChanged([queryParams.start, queryParams.end]);
    }
    if (queryParams.matrixExportAggregateByLocationGroup) {
      this.form.matrixExportAggregateByLocationGroup = queryParams.matrixExportAggregateByLocationGroup === 'true';
    }
    this.checkParameters();
  },
  computed: _objectSpread(_objectSpread({}, mapState({
    currentUser: function currentUser(state) {
      return state.general.user;
    }
  })), {}, {
    currentUserIsSuperAdmin: function currentUserIsSuperAdmin() {
      return this.currentUser.authorities.includes('ROLE_SUPER_ADMIN');
    },
    requestUrl: function requestUrl() {
      var companyId = this.$store.state.general.companyId;
      var url = "/company/".concat(companyId, "/export/csv");
      var startParam = 'start=' + moment(this.form.dateRange[0]).format(this.dateFormat);
      var endParam = 'end=' + moment(this.form.dateRange[1]).format(this.dateFormat);
      var selectedExportTypeParam = 'selectedExportType=' + this.form.type.toUpperCase();
      var matrixExportAggregateByLocationGroupParam = 'matrixExportAggregateByLocationGroup=' + this.form.matrixExportAggregateByLocationGroup;
      var langParam = 'language=' + this.language;
      var excelParam = 'excelEncoding=' + (this.charset === 'excel' ? 'true' : 'false');
      var locationParam = 'l=' + this.form.locationIds.join(',');
      var requestUrlWithoutLocations = "".concat(API_URL).concat(url, "?").concat(startParam, "&").concat(endParam, "&").concat(selectedExportTypeParam, "&").concat(matrixExportAggregateByLocationGroupParam, "&").concat(langParam, "&").concat(excelParam);
      if (this.form.locationIds.length === 0) {
        return requestUrlWithoutLocations;
      }
      return "".concat(requestUrlWithoutLocations, "&").concat(locationParam);
    },
    checkDataTitle: function checkDataTitle() {
      if (!this.isLoading && !this.noData) {
        switch (this.form.type) {
          case 'feedbacks':
            return this.$t('tooltip.checkDataTitleFeedbacks', {
              numberOfFeedbacks: FormatUtils.escapeHTML(this.$options.filters.groupedNumber(this.checkData.numberOfFeedbacks))
            });
          case 'reasons':
            return this.$t('tooltip.checkDataTitleRatingReasons', {
              numberOfRatingReasons: FormatUtils.escapeHTML(this.$options.filters.groupedNumber(this.checkData.numberOfRatingReasons))
            });
          case 'matrix':
            return this.$t('tooltip.checkDataTitleMatrix', {
              numberOfFeedbacks: FormatUtils.escapeHTML(this.$options.filters.groupedNumber(this.checkData.numberOfFeedbacks)),
              numberOfLocationsWithData: FormatUtils.escapeHTML(this.$options.filters.groupedNumber(this.checkData.numberOfLocationsWithData))
            });
          default:
            return '';
        }
      }
      return '';
    }
  }),
  created: function created() {
    if (this.allowedLanguages.indexOf(this.$store.state.general.language) !== -1) {
      this.language = this.$store.state.general.language;
    }
    this.form.dateRange = QueryUtils.getDateRangeFilter();
    CompanyPropertiesService.update();
  },
  methods: {
    checkParameters: function checkParameters() {
      var _this2 = this;
      if (this.isLoading) {
        this.checkIsWaiting = true;
        return;
      } else {
        this.checkIsWaiting = false;
      }
      this.isLoading = true;
      this.noData = false;
      this.checkData = {};
      CsvCheck.check.apply(null, [this.$store.state.general.companyId, this.form.locationIds, moment(this.form.dateRange[0]).format(this.dateFormat), moment(this.form.dateRange[1]).format(this.dateFormat), this.language, this.form.type, this.form.matrixExportAggregateByLocationGroup]).then(function (response) {
        _this2.isLoading = false;
        _this2.enableFormWatcher = true;
        _this2.checkData = response.data;
        if (_this2.checkIsWaiting) {
          _this2.checkParameters();
        }
      }, function () {
        _this2.isLoading = false;
        _this2.noData = true;
        _this2.enableFormWatcher = true;
        if (_this2.checkIsWaiting) {
          _this2.checkParameters();
        }
      });
    },
    changeLocations: function changeLocations(newLocations) {
      if (newLocations.queryLocations) {
        this.form.locationIds = newLocations.queryLocations;
        this.filter.location = newLocations;
      } else {
        this.form.locationIds = [];
        this.filter.location = {};
      }
    },
    dateRangeChanged: function dateRangeChanged(newDateRange) {
      this.form.dateRange = [moment(newDateRange[0]).toDate(), moment(newDateRange[1]).toDate()];
    }
  },
  watch: {
    form: {
      deep: true,
      handler: function handler() {
        if (this.enableFormWatcher) {
          this.checkParameters();
        }
      }
    }
  }
};